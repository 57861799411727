

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";
import striptags from "striptags";

import { HollowDotsSpinner } from "epic-spinners";
import { log } from "util";
@Component({
  components: { HollowDotsSpinner },
})
export default class WemloVendors extends Vue {
  public dotLoader = false;
  public addwemVendor = {};
  public appraisal = "Appraisal";
  public vendorData = [];
  public editVendors = [];
  public id = null;
  public inProcess = false;
  public searchList = [];
  public searchName = "";
  public allVendors = [];
  public vendorLoader = false;
  public loadMoreScroll = true;
  public searchString = null;
  public appraisalVenShowingPassword = [];
  public currentLengthOnScroll = 0;
  public wemloVendorLen = 0;
  public skip = 0;

  public togglePasswordDisplay(role) {
    this.appraisalVenShowingPassword = this.appraisalVenShowingPassword.includes(
      role._id
    )
      ? this.appraisalVenShowingPassword.filter((id) => id != role._id)
      : [role._id, ...this.appraisalVenShowingPassword];
  }

  public async addWemloVendor() {
    try {
      let formValid = await this.$validator.validateAll("venderInfo");
      if (!formValid) {
        return;
      }
      this.inProcess = true;
      await Axios.post(
        BASE_API_URL + "wemloStaff/getWemloVendors",
        {
          wemloVendorData: this.addwemVendor,
          appraisal: this.appraisal,
          userId: this.$userId,
        },
        {
        }
      );
      this.$snotify.success("Data is Submitted");
      this.addwemVendor = {};
      this.$modal.hide("addWemloVendors");
      await this.getTotalWemloVendorCount();
      await this.getWemloVendorDetails(true);
      this.inProcess = false;
    } catch (error) {
      console.log(error);
    }
  }

  public async showMoreDataOnScroll() {
    if (!this.loadMoreScroll && this.currentLengthOnScroll < this.wemloVendorLen) {
      this.loadMoreScroll = true;
      await this.getWemloVendorDetails(false);
    }
  }

  public async getWemloVendorDetails(mountCall: boolean = false) {
    const limit = 25;
    this.skip = mountCall ? 0 : this.skip + limit;
    if (mountCall) {
      this.vendorLoader = true;
    }
    try {
      const response = await Axios.post(
        BASE_API_URL + "wemloStaff/getWemloVendorData",
        {
          options: {
            skip: this.skip,
            limit,
          },
          searchString: this.searchString,
        },
        {
        }
      );

      if (mountCall) {
        this.vendorData = response.data.vendorData;
        this.allVendors = response.data.vendorData;
      } else {
        this.vendorData = this.vendorData.concat(response.data.vendorData);
        this.allVendors = this.allVendors.concat(response.data.vendorData);
      }

      this.searchList = response.data.vendorData;
      this.currentLengthOnScroll = this.allVendors.length;
    } catch (error) {
      console.log(error);
    } finally {
      this.vendorLoader = false;
      this.loadMoreScroll = false;
    }
  }
  public editVendor(i, id) {
    try {
      this.editVendors = this.vendorData[i];
      this.id = id;
      this.$modal.show("updateVendor");
    } catch (error) {
      console.log(error);
    }
  }
  public openConfirmationModal(id) {
    try {
      this.id = id;
      this.$modal.show("confirmDeleteFileModal");
    } catch (error) {
      console.log(error);
    }
  }
  public async updateVendors(id) {
    try {
      let formValid = await this.$validator.validateAll("vendorInfoUpdate");
      if (!formValid) {
        return;
      }
      this.inProcess = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/updateVendors",
        {
          id: id,
          vendorData: this.editVendors,
        },
        {
        }
      );
      this.$snotify.success("Data is Updated Successfully");
      this.$modal.hide("updateVendor");
      this.inProcess = false;
    } catch (error) {
      console.log(error);
    }
  }
  public async deleteVendorData(id) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/deleteVendor",
        {
          id: id,
        },
        {
        }
      );
      this.$snotify.success("Data is Deleted Successfully");
      this.$modal.hide("confirmDeleteFileModal");
      this.getTotalWemloVendorCount();
      //this.pagination = JSON.parse(JSON.stringify(this.initialPagination));
      this.getWemloVendorDetails(true);
    } catch (error) {
      console.log(error);
    }
  }

  public async searchByName() {
    try {
      if (this.searchName) {
        this.vendorData = this.searchList.filter((user) => {
          if (
            user.vendorType
              .toLowerCase()
              .includes(this.searchName.toLowerCase()) ||
            user.vendorName
              .toLowerCase()
              .includes(this.searchName.toLowerCase())
          ) {
            return user;
          }
        });
      } else {
        this.vendorData = this.allVendors;
      }
    } catch (error) {}
  }

  public async getTotalWemloVendorCount() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/getTotalWemloVendorCount/",
        {
          userId: this.$userId,
        },
        {
        }
      );
      this.wemloVendorLen = response.data.wemloVendorLen;
    } catch (error) {
      console.log(error);
    }
  }
  async mounted() {
    await this.getWemloVendorDetails(true);
    await this.getTotalWemloVendorCount();
  }
}
